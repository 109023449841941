<!-- 
Author:- Yogesh Dangi
Date :- 28/01/2025
Module :- Alert management System for admin
-->

<template>
  <v-container id="alerts" fluid tag="section">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-row justify="center">
      <v-dialog v-model="toggleAlertModel" max-width="600">
        <template>
          <v-card>
            <v-form ref="addReportForm" lazy-validation>
              <v-card-title>
                <span v-if="!alertEditMode && !alertViewMode" class="headline"
                  >Add Alert</span
                >
                <span v-if="alertEditMode && !alertViewMode" class="headline"
                  >Edit Alert</span
                >
                <span v-if="alertViewMode" class="headline">View Alert</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          no-title
                          v-model="alertDate"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          variant="outlined"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="alertDate"
                        variant="outlined"
                        no-title
                        scrollable
                        ref="datepicker"
                      ></v-date-picker>
                    </v-menu>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="alertTitle"
                      :counter="100"
                      label="Title*"
                      outlined
                      dense
                      required
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      name="input-7-1"
                      required
                      outlined
                      dense
                      v-model="alertDesc"
                      label="Description*"
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="closeViewMode"
                  v-if="alertViewMode"
                >
                  close
                </v-btn>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="saveNewAlert"
                  v-if="!alertEditMode && !alertViewMode"
                >
                  Save
                </v-btn>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="updateAlert"
                  v-if="alertEditMode && !alertViewMode"
                >
                  Update
                </v-btn>
                <v-btn
                  color="default"
                  @click="cancelAlertEditBoX"
                  v-if="!alertViewMode"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>

    <!-- Block to dispaly list of alerts -->
    <v-row justify="center">
      <v-col align-self="start">
        <p>
          <strong>Note:</strong> Click any of the following to view the message.
        </p>
      </v-col>

      <v-col align-self="end">
        <div class="text-right">
          <v-btn
            small
            color="primary"
            dark
            class="mr-4"
            @click="showAlertOnClient()"
          >
            Update
          </v-btn>

          <v-btn
            small
            color="primary"
            dark
            class="mr-4"
            @click="openAlertModel()"
          >
            Add Alert
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- Table to show list of alerts -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <div>
            <v-data-table
              :headers="headers"
              item-key="maID"
              :items="alert_data"
              :loading="loading"
              class="elevation-1"
              hide-default-footer
              :items-per-page="perpage"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  title="View"
                  @click="viewAlert(item.maID)"
                >
                  mdi-eye-outline
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  title="Edit Alert"
                  @click="openAlertEditModel(true, item.maID)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:item.active="{ item }">
                <v-checkbox
                  v-model="item.maActive"
                  :input-value="selectedAlerts.includes(item.maID)"
                  @change="addAlertForUpdate(item.maID, $event)"
                ></v-checkbox>
              </template>
            </v-data-table>
          </div>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :totPage="totPage"
              :perpage="perpage"
              :isCurrentPageClass="isCurrentPageClass"
              :getLastPageClass="getLastPageClass"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import moment from "moment";

export default {
  name: "ManageReports",

  components: {
    Loading,
    Pagination,
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    // isCurrentPageClass: function () {
    //   return this.currentPage === 1 ? "" : "notactivespan";
    // },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },

    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },

    // lastPage: function () {
    //   console.log(this.totRecords,)
    //   console.log("last page >>>>",Math.ceil(this.totRecords/this.totPage)+1)
    //   return Math.ceil(this.totRecords/this.totPage)+1
    // },
  },

  data: () => ({
    //for loading//
    loading: false,
    isCurrentPageClass: "notactivespan",
    isLoading: false,
    alertEditMode: false,
    alertViewMode: false,
    toggleAlertModel: false,
    alertID: "",
    fullPage: true,

    //pagination code
    perpage: 10,
    totRecords: "",

    totPage: "",
    currentPage: 1,
    lastPage: "",

    showPerPage: true,
    ///
    //////table data starts here////
    selectedAlerts: [],
    options: {},

    page: 1,
    alert_data: [],
    pageCount: 0,
    totalReports: 0,
    alertTitle: "",
    alertDesc: "",
    // pagination: {
    //   rowsPerPage: 100,
    //   descending: false,
    //    sortable: false,
    //   page: 1
    // },
    editReportMode: false,
    fromDateMenu: false,
    fromDateVal: null,
    alertDate: "",

    headers: [
      {
        text: "Date",
        value: "maDate",
        width: "150px",
        sortable: false,
      },
      { text: "Title", value: "maTitle", sortable: false },
      {
        text: "Actions",
        value: "actions",
        width: "150px",
        sortable: false,
        align: "end",
        class: "mr-2",
      },
      { text: "Active", value: "active", width: "150px", sortable: false },
    ],
  }),

  async mounted() {
    this.getAlertData();
  },
  methods: {
    getAlertData() {
      let view = this;
      view.isLoading = true;
      let manage_reports_url =this.$url('ALERT_LIST')
       
      const config = { page_number: this.currentPage, page_size: this.perpage };

      try {
        view
          .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
          .post(manage_reports_url, config)
          .then((response) => {
            view.isLoading = false;
            view.page = response.current_page;
            view.perpage = response.data.page_size;
            view.alert_data = response.data.data;
            view.selectedAlerts = response.data.data
              .filter((item) => item.maActive)
              .map((item) => item.maID);
            view.totRecords = response.data.total_records;
            view.totPage = parseInt(response.data.total_pages);
            view.lastPage = Math.ceil(view.totRecords / view.perpage);
          });
      } catch (error) {
        console.error(error);
        view.isLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong!",
        });
      } finally {
        this.loading = false;
      }
    },

    // author : Yogesh Dangi
    // data :  31/01/2025
    // task : addAlertForUpdate funtion to select alert id to be dispalyed
    addAlertForUpdate(alertId, isChecked) {
      // If checkbox is checked and ID isn't in array, add it
      if (isChecked && !this.selectedAlerts.includes(alertId)) {
        this.selectedAlerts.push(alertId);
      }
      // If checkbox is unchecked and ID is in array, remove it
      else if (!isChecked && this.selectedAlerts.includes(alertId)) {
        const index = this.selectedAlerts.indexOf(alertId);
        this.selectedAlerts.splice(index, 1);
      }

      // Optional: Log the current state of selections
      console.log("Currently selected alerts:", this.selectedAlerts);
    },
    saveNewAlert() {
      let view = this;
      view.isLoading = true;
      this.alertViewMode = false;
      let manage_reports_url = this.$url('ALERT_DATA');
      const payload = {
        maTitle: this.alertTitle,
        maDate: moment(this.alertDate).format("YYYY-MM-DD"),
        maContent: this.alertDesc,
        maActive: 0,
      };

      try {
      view.isLoading = true;
        view
          .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
          .post(manage_reports_url, payload)
          .then((response) => {
            console.log(response)
            if(response.data.status_code==200){
              this.$swal.fire({
                icon: "success",
                title: "Success",
                text: "Alert added successfully",
              }).then((res)=>{
                if(res.isConfirmed){
                  view.isLoading = false;
                  this.getAlertData();
                  this.toggleAlertModel = false;
                }
              });
            }
          });
      } catch (error) {
        console.error(error);
        view.isLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong!",
        });
      } finally {
        view.isLoading = false;
      }
    },
    openAlertModel() {
      this.alertTitle = "";
      this.alertDesc = "";
      this.toggleAlertModel = true;
      this.alertEditMode = false;
      this.alertDate = moment(new Date()).format("YYYY-MM-DD");
    },
    cancelAlertEditBoX() {
      this.toggleAlertModel = false;
      this.alertTitle = "";
      this.alertDesc = "";
      this.alertDate = "";
      this.alertID = "";
    },

    openAlertEditModel(val, id) {
      this.alertEditMode = val;
      this.alertViewMode = !val;
      this.toggleAlertModel = true;
      if (this.alert_data != undefined) {
        for (let i = 0; i < this.alert_data.length; i++) {
          let ele = this.alert_data[i];
          if (ele.maID == id) {
            this.alertID = id;
            this.alertTitle = ele.maTitle;
            this.alertDesc = ele.maContent;
            this.alertDate = moment(ele.maDate).format("YYYY-MM-DD");
            break;
          }
        }
      }
    },
    viewAlert(id) {
      this.alertViewMode = true;
      this.toggleAlertModel = true;
      if (this.alert_data != undefined) {
        for (let i = 0; i < this.alert_data.length; i++) {
          let ele = this.alert_data[i];
          if (ele.maID == id) {
            this.alertID = id;
            this.alertTitle = ele.maTitle;
            this.alertDesc = ele.maContent;
            this.alertDate = moment(ele.maDate).format("YYYY-MM-DD");
            break;
          }
        }
      }
    },
    closeViewMode() {
      this.alertTitle = "";
      this.alertDesc = "";
      this.alertDate = "";
      this.alertID = "";
      this.toggleAlertModel = false;
    },
    showAlertOnClient() {
      if (this.selectedAlerts.length > 0) {
        const payload = {
          maIDs: this.selectedAlerts,
          maActive: 1,
        };
        let manage_alert_url = this.$url('ALERT_DATA');
        try {
          this.isLoading = true;
          this.$fetch({ requiresAuth: true, operation: "patch" })
            .patch(manage_alert_url, payload)
            .then((response) => {
              console.log("res>>>>>", response);
              if (response.data.status_code !== 500) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Alert Updated",
                    text: "Selected Alerts Updated to client side",
                  })
                  .then((res) => {
                    if (res.isConfirmed) {
                      console.log(response);
                      this.isLoading = false;
                      this.toggleAlertModel = false;
                      this.getAlertData();
                    }
                  });
              } else {
                this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.message,
                });
              }
            });
        } catch (error) {
          console.error(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Atlest select one Alert!",
        });
      }
    },
    updateAlert() {
      this.isLoading = true;
      this.alertViewMode = false;
      let manage_alert_url = this.$url('ALERT_DATA');

      const payload = {
        maID: this.alertID,
        maTitle: this.alertTitle,
        maDate: moment(this.alertDate).format("YYYY-MM-DD"),
        maContent: this.alertDesc,
        maActive: 0,
      };

      try {
        this.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "put" })
          .put(manage_alert_url, payload)
          .then((response) => {
            this.$swal
              .fire({
                icon: "success",
                title: "Alert Updated",
              })
              .then((res) => {
                if (res.isConfirmed) {
                  console.log(response);
                  this.isLoading = false;
                  this.toggleAlertModel = false;
                  this.getAlertData();
                }
              });
          });
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong!",
        });
      } finally {
        this.isLoading = false;
      }
    },

    getDataByPage(page) {
      let view = this;
      console.log("Data", page);
      this.currentPage = page;
      view.getAlertData();
    },
    handlePerPage(p) {
      console.log("perpage", p);
      let view = this;
      this.perpage = p;
      this.currentPage = 1;
      this.getAlertData();
    },
    paginate(n) {
      console.log(n);
      let view = this;
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let myPromise = new Promise(function (resolve, reject) {
        resolve(n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n));
        reject("Error: on set page");
      });
      myPromise
        .then(function () {
          getAlertData();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    last() {
      let view = this;
      console.log("last", view.lastPage);
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
        return;
      }
      this.currentPage = this.lastPage;
      this.getAlertData();
    },
  },
};
</script>
